import React, { useState } from 'react';
import { Button, Select, MenuItem, Modal, Box, TextField, Checkbox, TableRow, TableCell } from '@mui/material';
import DurationPicker from 'react-duration-picker';
import { toast } from 'react-toastify';
import api from '../network/ApiClient';

const AddLeg = ({ locations, setShowNewLegInputs, setLegs, id }) => {
  const [newLeg, setNewLeg] = useState({
    location: '',
    eta: { hours: 0, minutes: 0, seconds: 0 },
    dwell: { hours: 0, minutes: 0, seconds: 0 },
    cost: '',
    embark: true,
    disembark: true,
  });

  const [loading, setLoading] = useState(false);
  const [isEtaModalOpen, setEtaModalOpen] = useState(false);
  const [isDwellModalOpen, setDwellModalOpen] = useState(false);

  const handleAddLeg = async () => {
    const errors = [];
    if (!newLeg.location) errors.push('Location');
    if (!newLeg.cost) errors.push('Cost');
    if (newLeg.eta.hours === 0 && newLeg.eta.minutes === 0) errors.push('Duration');
    if (newLeg.dwell.hours === 0 && newLeg.dwell.minutes === 0) errors.push('Dwell');
    if (newLeg.dwell.minutes === 0 && newLeg.dwell.seconds <= 59) {
      toast.error("Dwell can't be less than 1 minute")
      return;
    }
    if (errors.length > 0) {
      toast.error(`Please fill all fields: ${errors.join(', ')}`);
      return;
    }

    setLoading(true);

    try {
      const legData = {
        location: Number(newLeg.location),
        eta: formatDuration(newLeg.eta),
        dwell: formatDuration(newLeg.dwell),
        cost: newLeg.cost,
        access: handleAccessCalculation(newLeg.embark, newLeg.disembark).toString(),
      };
      await api.post(`${process.env.REACT_APP_API_URL}route/${id}/addleg`, legData);
      setLegs((prevLegs) => [...prevLegs, legData]);
      resetForm();
      setShowNewLegInputs(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setNewLeg({
      location: '',
      eta: { hours: 0, minutes: 0, seconds: 0 },
      dwell: { hours: 0, minutes: 0, seconds: 0 },
      cost: '',
      embark: false,
      disembark: false,
    });
  };

  const formatDuration = (duration) => {
    const { hours, minutes, seconds } = duration;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  const handleAccessCalculation = (embark, disembark) => {
    let access = '0';
    if (embark && disembark) {
      access = '3';
    } else if (embark) {
      access = '2';
    } else if (disembark) {
      access = '1';
    }
    return access;
  };

  const handleDurationChange = (type, value) => {
    setNewLeg((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  return (
    <>
      <TableRow>
        <TableCell sx={{ width: '10%' }}>New</TableCell>
        <TableCell sx={{ width: '20%' }}>
          <Select
            value={newLeg.location}
            onChange={(e) => setNewLeg({ ...newLeg, location: e.target.value })}
            sx={{ width: '200px' }}
          >
            {Object.entries(locations).map(([id, name]) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </TableCell>

        <TableCell sx={{ width: '15%' }}>
          <Button variant="outlined" color="primary" onClick={() => setEtaModalOpen(true)}>
            {formatDuration(newLeg.eta)}
          </Button>
          <Modal open={isEtaModalOpen} onClose={() => setEtaModalOpen(false)}>
            <Box
              sx={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '2rem',
                p: 2,
              }}
            >
              <DurationPicker
                initialDuration={{ hours: 0, minutes: 0, seconds: 0 }}
                onChange={(value) => handleDurationChange('eta', value)}
              />
              <Button variant="contained" onClick={() => setEtaModalOpen(false)}>
                Save ETA
              </Button>
            </Box>
          </Modal>
        </TableCell>

        <TableCell sx={{ width: '15%' }}>
          <Button variant="outlined" color="primary" onClick={() => setDwellModalOpen(true)}>
            {formatDuration(newLeg.dwell)}
          </Button>
          <Modal open={isDwellModalOpen} onClose={() => setDwellModalOpen(false)}>
            <Box
              sx={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '2rem',
                p: 2,
              }}
            >
              <DurationPicker
                initialDuration={{ hours: 0, minutes: 0, seconds: 0 }}
                onChange={(value) => handleDurationChange('dwell', value)}
              />
              <Button variant="contained" onClick={() => setDwellModalOpen(false)}>
                Save Dwell
              </Button>
            </Box>
          </Modal>
        </TableCell>
        {/* 
        <TableCell sx={{ width: '0%' }}>
          <TextField fullWidth sx={{ border: 'none', outline: 'none' }} placeholder="Calculated" disabled />
        </TableCell> */}
        <TableCell sx={{ width: '20%' }}>
          <TextField
            fullWidth
            type="number"
            value={newLeg.cost}
            onChange={(e) => setNewLeg({ ...newLeg, cost: e.target.value })}
            placeholder="Cost"
          />
        </TableCell>

        <TableCell>
          <Checkbox checked={newLeg.embark} onChange={(e) => setNewLeg({ ...newLeg, embark: e.target.checked })} />
        </TableCell>

        <TableCell sx={{ width: '10%' }}>
          <Checkbox
            checked={newLeg.disembark}
            onChange={(e) => setNewLeg({ ...newLeg, disembark: e.target.checked })}
          />
        </TableCell>

        <TableCell sx={{ width: '10%' }}>
          <Button variant="contained" color="primary" onClick={handleAddLeg} disabled={loading}>
            {loading ? 'Adding...' : 'Add'}
          </Button>
          <Button variant="outlined" color="secondary" onClick={() => setShowNewLegInputs(false)}>
            Cancel
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AddLeg;
