import React, { useState, useEffect } from 'react';
import { FormControl, Box, Button, Stack, Modal, Grid, TextField, Autocomplete, Select, MenuItem, InputLabel } from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import api from '../network/ApiClient';
import { loadLocations } from '../locations/Locations';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    md: 500,
    xs: '100%',
  },
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: {
    md: 4,
    xs: 2,
  },
};

const LocationSelector = ({ locations, selectedLocation, onLocationChange }) => (
  <Autocomplete
    options={locations.data}
    getOptionLabel={(loc) => loc.name}
    value={locations.data.find((loc) => loc.id === selectedLocation) || null}
    onChange={(event, newValue) => {
      onLocationChange(newValue?.id || '');
    }}
    renderInput={(params) => <TextField {...params} label="Select Starting Location" variant="outlined" fullWidth />}
    isOptionEqualToValue={(option, value) => option.id === value.id}
    freeSolo
  />
);

export default function CreateRouteForm({ onCreateCallback, modalShow, setModalShow, onSubmitSuccess }) {
  const [locations, setLocations] = useState({ data: [] });
  const [optionState, setOptionState] = useState({ data: [] });

  useEffect(() => {
    const fetchLocations = async () => {
      await loadLocations(setLocations);
    };
    fetchLocations();
    loadOrganizerGroups();
  }, []);

  const handleClose = () => {
    setModalShow(false);
    formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      organizergroup: null,
      legs: [{ location: '', access: '2' }],
      cancellation: '', // New field for cancellation policy
      cancellationUnit: 'hours',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Route name is required'),
      organizergroup: Yup.object().required('Organizer Group is required.'),
      cancellation: Yup.string().required("Cancellation Policy is required."),
      legs: Yup.array().of(
        Yup.object({
          location: Yup.string().required('Please select a location'),
          access: Yup.string().required('Access is required'),
        })
      ),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const legsWithIds = values.legs.map((leg, index) => ({
          id: `${values.name}-${index}`,
          ...leg,
        }));


        const cancellationInHours = values.cancellationUnit === 'hours' ? values.cancellation : values.cancellation * 24;

        const newRoute = {
          id: `${values.name}-${Date.now()}`,
          organizergroup: values.organizergroup?.id,
          cancellation: cancellationInHours,
          name: values.name,
          legs: legsWithIds,
        };

        await api.post(`${process.env.REACT_APP_API_URL}/route`, newRoute);
        onCreateCallback(newRoute);
        onSubmitSuccess();
      } catch (error) {
        console.error('Error submitting form:', error);
      } finally {
        setSubmitting(false);
        handleClose();
      }
    },
  });

  const handleAddLegChange = (key, value) => {
    formik.setFieldValue('legs', [{ ...formik.values.legs[0], [key]: value }]);
  };

  const loadOrganizerGroups = async () => {
    await api
      .get('organizergroup', {
        params: {},
      })
      .then((res) => {
        const organizerGroups = res.data.organizer || [];
        // const organizerGroups = [res.data.organizer[0]] || [];
        setOptionState({ ...optionState, data: res.data.organizer });
        // setOptionState({ ...optionState, data: [res.data.organizer[0]]});
              // If only one organizer group, set it directly in formik
        if (organizerGroups.length === 1 && !formik.values.organizergroup) {
          formik.setFieldValue('organizergroup', organizerGroups[0]);
        }
      })
      .catch((e) => {
        console.log('unable to  load operators ', e);
      });
  };

  return (
    <Modal
      open={modalShow}
      onClose={handleClose}
      aria-labelledby="create-route-modal"
      aria-describedby="create-route-form"
    >
      <Box sx={style}>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={3}>
            <h4 className="card-title mb-0">Create Route</h4>

            <TextField
              fullWidth
              name="name"
              label="Route Name"
              onChange={formik.handleChange}
              value={formik.values.name}
              variant="outlined"
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              InputLabelProps={{ shrink: true }}
            />

          <FormControl error={Boolean(formik.errors.organizergroup && formik.touched.organizergroup)} fullWidth>
              <Autocomplete
                options={optionState.data}
                onChange={(event, value) => {formik.setFieldValue('organizergroup', value);}}
                getOptionLabel={(option) => `${option.name}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Organizer Group"
                    variant="outlined"
                    error={Boolean(formik.errors.organizergroup && formik.touched.organizergroup)}
                    helperText={formik.errors.organizergroup && formik.touched.organizergroup ? formik.errors.organizergroup : ''}
                    InputProps={{
                      ...params.InputProps,
                      readOnly: optionState.data.length === 1, // Disable if only one option
                    }}
                  />
                )}
                // If only one item, disable selection, and auto-select it
                value={optionState.data.length === 1 ? optionState.data[0] : formik.values.organizergroup}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </FormControl>

            <LocationSelector
              locations={locations}
              selectedLocation={formik.values.legs[0]?.location}
              onLocationChange={(value) => handleAddLegChange('location', value)}
            />

<Grid container fullWidth>
            <Grid item xs={8}>
<TextField
              fullWidth
              name="cancellation"
              label="Cancellation Policy"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.cancellation}
              variant="outlined"
              error={formik.touched.cancellation && Boolean(formik.errors.cancellation)}
              helperText={formik.touched.cancellation && formik.errors.cancellation}
              InputLabelProps={{ shrink: true }}
              sx={{ pr: 1 }}
            />
            </Grid>

            {/* Dropdown for selecting "hours" or "days" */}
            <Grid item xs={4}>
            <FormControl fullWidth sx={{ pl: 1 }}>
              <InputLabel>Cancellation Unit</InputLabel>
              <Select
                value={formik.values.cancellationUnit}
                onChange={(e) => formik.setFieldValue('cancellationUnit', e.target.value)}
                label="Cancellation Unit"
              >
                <MenuItem value="hours">Hours</MenuItem>
                <MenuItem value="days">Days</MenuItem>
              </Select>
            </FormControl>
            </Grid>
            </Grid>
          </Stack>

          <Grid container sx={{ mt: 2 }} justifyContent="space-between">
            <Grid item xs={6}>
              <Button
                variant="contained"
                type="submit"
                disabled={formik.isSubmitting} // Disable button while submitting
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={6} textAlign="right">
              <Button color="error" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
}
